import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from "../../layouts/layout"
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

export default function Orders() {

    const adminRole = localStorage.getItem('admin_role');
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [productList, setProductList] = useState([]);
    const [allPage, setAllPage] = useState(0);
    const [filterCompany, setFilterCompany] = useState('all');
    const [filterStatus, setFilterStatus] = useState('all');
    const [allAdmin, setAllAdmin] = useState([]);
    const [exportPath, setExportPath] = useState('/order/export');
    const [searchText, setSearchText] = useState('');

    const navigate = useNavigate();
    let page = useRef(1);

    const convertWebsiteTotable = (website) => {
        switch (website) {
            case 'gse-stores.com':
                return 'web_order';
            case 'mcapital.co.th':
                return 'mcapital_orders';
            case 'gseinsurebroker.com':
                return 'gseinsure_orders';
            default:
                return 'ไม่ระบุ';
        }
    }

    const fetchProducts = useCallback((value = 1, fill = 'all', status = 'all', search = null) => {
        setIsLoading(true)
        let requestURL = `${process.env.REACT_APP_BACKEND_URL}/order/fetch-all-company/${value}/${fill}/${status}`;
        if (search) {
            requestURL = `${process.env.REACT_APP_BACKEND_URL}/order/fetch-all-company/${value}/${fill}/${status}?search=${search}`;
        }
        axios({
            method: 'get',
            url: requestURL,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setProductList(response.data.data);
            setIsLoading(false);
            setAllPage(response.data.all);
        }).catch(function (error) {
            console.log(error);
        });
    }, [setIsLoading]);

    useEffect(() => {
        if (localStorage.getItem('admin_role') === '1') {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/admin/fetch-all`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                },
            }).then(function (response) {
                if (response.data.status === 200) {
                    setAllAdmin(response.data.data);
                }
            }).catch(function (error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: 'ไม่สามารถเรียกข้อมูล Admin ได้'
                });
            });
        }
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    const fetchNextPage = (event, value) => {
        page.current = value;

        // Check if search value is not empty or filter company is not all and filter status is not all
        if (searchText !== '' || filterCompany !== 'all' || filterStatus !== 'all') {
            fetchProducts(value, filterCompany, filterStatus, searchText);
            return;
        }


        fetchProducts(value, filterCompany);
    }

    /*
    const statusOption = [
        {
            value: 1,
            label: 'รอแจ้งชำระเงิน',
            color: 'text-gray-800',
            bg: 'bg-gray-100',
        },
        {
            value: 2,
            label: 'กำลังตรวจสอบการชำระเงิน',
            color: 'text-white',
            bg: 'bg-red-600',
        },
        {
            value: 3,
            label: 'กำลังเตรียมสินค้าเพื่อจัดส่ง',
            color: 'text-white',
            bg: 'bg-indigo-500',
        },
        {
            value: 4,
            label: 'สินค้าอยู่ระหว่างจัดส่ง',
            color: 'text-white',
            bg: 'bg-green-500',
        },
        {
            value: 5,
            label: 'จัดส่งสินค้าแล้ว',
            color: 'text-gray-800',
            bg: 'bg-gray-200',
        },
        {
            value: 6,
            label: 'ยกเลิก',
            color: 'text-gray-800',
            bg: 'bg-gray-200',
        },
        {
            value: 7,
            label: 'คืนเงิน',
            color: 'text-gray-800',
            bg: 'bg-gray-200',
        },
        {
            value: 8,
            label: 'กำลังดำเนินการ',
            color: 'text-white',
            bg: 'bg-amber-500',
        },
        {
            value: 9,
            label: 'ดำเนินการเสร็จสิ้น',
            color: 'text-white',
            bg: 'bg-green-500',
        },
    ];
    */

    const statusOption2 = [
        {
            value: 8,
            label: 'รอการติดต่อกลับภายใน 24 ชั่วโมง',
            color: 'text-gray-800',
            bg: 'bg-gray-100',
        },
        {
            value: 10,
            label: 'กำลังดำเนินการ/รอการพิจารณา',
            color: 'text-white',
            bg: 'bg-amber-500',
        },
        {
            value: 11,
            label: 'อนุมัติ',
            color: 'text-white',
            bg: 'bg-indigo-500',
        },
        {
            value: 12,
            label: 'ไม่อนุมัติ',
            color: 'text-white',
            bg: 'bg-red-600',
        },
        {
            value: 9,
            label: 'ดำเนินการเสร็จสิ้น',
            color: 'text-white',
            bg: 'bg-green-500',
        },
    ];

    /*
    const whyNotApproveOption = [
        {
            value: 1,
            label: 'ติด NPL',
            color: 'text-gray-800',
            bg: 'bg-gray-100',
        },
        {
            value: 2,
            label: 'สภาพการเดินบัญชีไม่ปกติ',
            color: 'text-white',
            bg: 'bg-red-600',
        },
        {
            value: 3,
            label: 'เป็นลูกค้าปัจจุบัน',
            color: 'text-white',
            bg: 'bg-red-600',
        },
    ];
    */

    const handleFilter = (e) => {
        page.current = 1;
        setFilterCompany(e.target.value);
        setIsLoading(true)
        fetchProducts(1, e.target.value, filterStatus);
    }

    const convertToPill = (text, color, bg) => {
        return <span className={`px-4 py-1 text-xs whitespace-nowrap rounded-full ${color} ${bg}`}>{text}</span>
    }

    const [openDelete, setOpenDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteWebsite, setDeleteWebsite] = useState(null);

    const handleClickOpenDelete = (id, website) => {
        setDeleteId(id);
        setDeleteWebsite(convertWebsiteTotable(website));
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setDeleteId(null);
        setDeleteWebsite(null);
        setOpenDelete(false);
    };

    const requestDelete = (id, website) => {
        setIsLoading(true)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/order-v2/${id}/${website}/delete`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setIsLoading(false);
            fetchProducts(page.current, filterCompany, filterStatus);
            setAlert({
                open: true,
                type: 'success',
                msg: 'ลบข้อมูลสำเร็จ'
            });
        }).catch(function (error) {
            setIsLoading(false);
            setAlert({
                open: true,
                type: 'error',
                msg: 'ไม่สามารถลบข้อมูลได้'
            });
        });
    }

    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const handleFilterStatus = (e) => {
        page.current = 1;
        setFilterStatus(e.target.value);
        setIsLoading(true)
        fetchProducts(1, filterCompany, e.target.value);
    }

    function debounce(func, delay = 300) {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const debouncedFetchContents = debounce((searchValue) => {
        fetchProducts(1, filterCompany, filterStatus, searchValue);
    }, 500);

    const handleDebonceSearch = (e) => {
        let value = e.target.value;
        setSearchText(value);
        if (value === '') {
            fetchProducts(1, filterCompany, filterStatus);
            return;
        }
        debouncedFetchContents(value);
    }

    const convertToOrderID = (id, ref) => {
        switch (ref) {
            case 'gse-stores.com':
                return `GSE-${id.toString().padStart(5, '0')}`;
            case 'mcapital.co.th':
                return `M-${id.toString().padStart(5, '0')}`;
            case 'gseinsurebroker.com':
                return `GSI-${id.toString().padStart(5, '0')}`;
            default:
                return `${id.toString().padStart(5, '0')}`;

        }
    }

    const convertAdminIDToName = (id) => {
        let admin = allAdmin.find((element) => element.id === id);
        if (admin) {
            return admin.admin_name;
        }
        return 'ไม่ระบุ';
    }

    const [dailyDate, setDailyDate] = useState({
        startDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`,
        endDate: new Date().toISOString().slice(0, 10)
    });
    const [openExport, setOpenExport] = useState(false);

    const handleExport = () => {
        setIsLoading(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}${exportPath}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            params: {
                startDate: dayjs(dailyDate.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(dailyDate.endDate).format('YYYY-MM-DD'),
            }
        }).then(function (response) {
            setIsLoading(false);
            setOpenExport(false);


            const data = response.data.data;
            const maxProductCount = response.data.maxProductCount;
            const workbook = XLSX.utils.book_new();

            Object.keys(data).forEach((site) => {
                const siteData = data[site];
                const ws_data = [];

                // Set the column headers (labels) for the data
                const headers = [
                    "เลขที่", "วันที่สั่งซื้อ", "วันที่อนุมัติ", "เวลา", "ชื่อลูกค้า", "โทรศัพท์", "อาชีพ",
                    "ที่อยู่", "ตำบล", "อำเภอ", "จังหวัด", "รหัสไปรษณีย์", "สถานะ", 
                    "สาขา", "ยอดรวม", "บันทึกแอดมิน", "วันที่ออกสัญญา", "เลขที่สัญญา"
                ];

                if (site === "gse-stores.com") {
                    headers.push("สินค้าที่สนใจ");
                }

                // Add product column headers based on maxProductCount
                for (let i = 0; i < maxProductCount[site]; i++) {
                    headers.push(`ชื่อสินค้า ${i + 1}`);
                    headers.push(`จำนวน ${i + 1}`);
                    headers.push(`ราคาต่อหน่วย ${i + 1}`);
                    headers.push(`รวม ${i + 1}`);
                }

                // Add the headers to the sheet data
                ws_data.push(headers);

                siteData.forEach((entry) => {
                    let row = [
                        entry['เลขที่'], entry['วันที่'], entry['วันที่อนุมัติ'], entry['เวลา'], entry['ชื่อลูกค้า'],
                        entry['โทรศัพท์'], entry['อาชีพ'], entry['ที่อยู่'], entry['ตำบล'],
                        entry['อำเภอ'], entry['จังหวัด'], entry['รหัสไปรษณีย์'], entry['สถานะ'], 
                        entry['สาขา'], entry['ยอดรวม'], entry['บันทึกแอดมิน'], entry['วันที่ออกสัญญา'], entry['เลขที่สัญญา']
                    ];

                    if (site === "gse-stores.com") {
                        row.push(entry['สินค้าที่สนใจ']);
                    }

                    entry['สินค้า'].forEach((product, idx) => {
                        row.push(product['ชื่อสินค้า'], product['จำนวน'], product['ราคาต่อหน่วย'], product['รวม']);
                    });

                    // Fill any remaining product columns with empty values
                    for (let i = entry['สินค้า'].length; i < maxProductCount[site]; i++) {
                        row.push("", "", "", "");
                    }

                    ws_data.push(row);
                });

                const ws = XLSX.utils.aoa_to_sheet(ws_data);
                XLSX.utils.book_append_sheet(workbook, ws, site);
            });

            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'ExportedData.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);

        }).catch(function (error) {
            setIsLoading(false);
            setAlert({
                open: true,
                type: 'error',
                msg: 'ไม่สามารถส่งออกไฟล์ได้'
            });
        });
    }

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    };

    const filterCompanyOption = [
        {
            value: 'all',
            label: 'บริษัททั้งหมด',
            permissions: ['1', '2']
        },
        {
            value: 'web_order',
            label: 'gse-stores.com',
            permissions: ['1', '2']
        },
        {
            value: 'mcapital_orders',
            label: 'mcapital.co.th',
            permissions: ['1', '2']
        },
        {
            value: 'gseinsure_orders',
            label: 'gseinsurebroker.com',
            permissions: ['1', '2', '6']
        },
    ];

    const handleOpen = (path) => {
        setExportPath(path);
        setOpenExport(true);
    }

    return (
        <Layout>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex flex-col gap-4 2xl:flex-row justify-between w-full'>
                    <div className='flex flex-col lg:flex-row gap-4 lg:items-center'>
                        <h1 className='text-2xl font-bold hidden lg:block'>รายการคำสั่งซื้อ</h1>
                        <div className=''>
                            <select
                                className='border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-0 focus:border-gray-300 w-full'
                                value={filterCompany}
                                onChange={handleFilter}
                            >
                                {filterCompanyOption.map((company, index) => (
                                    company.permissions.includes(adminRole) && <option key={index} value={company.value}>{company.label}</option>
                                ))}
                                {/*<option value="all">บริษัททั้งหมด</option>
                                <option value="web_order">gse-stores.com</option>
                                <option value="mcapital_orders">mcapital.co.th</option>
                                <option value="gseinsure_orders">gseinsurebroker.com</option>*/}
                            </select>
                        </div>
                        <div className=''>
                            <select
                                className='border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-0 focus:border-gray-300 w-full'
                                value={filterStatus}
                                onChange={handleFilterStatus}
                            >
                                <option value="all">สถานะทั้งหมด</option>
                                {
                                    statusOption2.map((status, index) => (
                                        <option key={index} value={status.value}>{status.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='flex gap-4 mt-4 lg:mt-0 '>
                        <input
                            type='text'
                            className='border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-0 focus:border-gray-300 max-w-full '
                            placeholder='ค้นหาลูกค้า'
                            onChange={handleDebonceSearch}
                            style={{ width: '300px' }}
                        />

                        {/*<button disabled={isLoading} className=' underline' onClick={() => handleOpen('/order/export-result')}>
                           ผลการอนุมัติ
                        </button>*/}

                        <button disabled={isLoading} className='bg-indigo-800 px-4 rounded-md text-white' onClick={() => handleOpen('/order/export')}>
                            Export as Excel
                        </button>
                    </div>
                </div>

                <div className='table-row-warp mt-8 flex-grow overflow-x-auto'>
                    <div className='table-inner-wrap'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`}>
                            <div className='w-2/12  font-bold'>#</div>
                            <div className='w-2/12  font-bold'>ลูกค้า</div>
                            <div className='w-2/12  font-bold'>วันที่</div>
                            <div className='w-2/12 text-center font-bold'>สถานะ</div>
                            <div className='w-1/12 text-right font-bold'>จากเว็บไซต์</div>
                            {localStorage.getItem('admin_role') === '1' && <div className='w-2/12 text-right font-bold'>สาขา</div>}
                            <div className={`${localStorage.getItem('admin_role') === '1' ? 'w-2/12' : 'w-4/12'} text-right font-bold`}>จัดการ</div>
                        </div>
                        {productList.length > 0 && productList.map((product, index) => (
                            <div className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50' : ''}`} key={index}>
                                <div className='w-2/12 '><Link to={`/order/${product.ref}/${product.orderid}`}>{convertToOrderID(product.orderid, product.ref)}</Link></div>
                                <div className='w-2/12 '>{product.customerName}</div>
                                <div className='w-2/12 '>
                                    <span className='pr-2'>
                                        {
                                            new Date(product.date).toLocaleDateString('th-TH', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })
                                        }
                                    </span>
                                    <span>
                                        {
                                            new Date(product.date).toLocaleTimeString('th-TH', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })
                                        }
                                    </span>
                                </div>
                                <div className='w-2/12 text-center'>
                                    {
                                        convertToPill(
                                            statusOption2.find((element) => element.value === product.status).label,
                                            statusOption2.find((element) => element.value === product.status).color,
                                            statusOption2.find((element) => element.value === product.status).bg,
                                        )
                                    }
                                </div>
                                <div className='w-1/12 text-right'>{product.ref}</div>
                                {localStorage.getItem('admin_role') === '1' && <div className='w-2/12 text-right'>
                                    {
                                        product.assignTo ? convertAdminIDToName(product.assignTo) : 'ไม่ระบุ'
                                    }
                                </div>}
                                <div className={`${localStorage.getItem('admin_role') === '1' ? 'w-2/12' : 'w-4/12'} flex justify-end gap-2`}>

                                    <Tooltip title="ดู / แก้ไข" placement="top" arrow >
                                        <IconButton aria-label="edit" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => navigate(`/order/${product.ref}/${product.orderid}`)}>
                                            <ModeEditOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="ลบ" placement="top" arrow>
                                        <IconButton aria-label="delete" onClick={() => handleClickOpenDelete(product.orderid, product.ref)} sx={{ backgroundColor: '#f1f7fe' }}>
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='sticky bottom-0 w-full bg-white p-2 flex justify-between items-center'>
                    <div>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>

            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ยืนยันการลบข้อมูล
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        เมื่อลบข้อมูลแล้วจะไม่สามารถกู้คืนได้ คุณต้องการลบข้อมูลหรือไม่?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>ยกเลิก</Button>
                    <Button onClick={() => {
                        requestDelete(deleteId, deleteWebsite);
                        handleCloseDelete();
                    }} autoFocus>
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openExport}
                onClose={() => setOpenExport(false)}
                maxWidth='xs'
                fullWidth
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className='p-8'>
                        <div className='mb-6'>
                            <DatePicker
                                label="วันที่เริ่มต้น"
                                value={dailyDate.startDate}
                                onChange={(newValue) => {
                                    setDailyDate({
                                        ...dailyDate,
                                        startDate: newValue.format("YYYY-MM-DD"),
                                    });
                                }}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => <TextField fullWidth {...params} size="small" />}
                            />
                        </div>
                        <div className='mb-4'>
                            <DatePicker
                                label="วันที่สิ้นสุด"
                                value={dailyDate.endDate}
                                onChange={(newValue) => {
                                    setDailyDate({
                                        ...dailyDate,
                                        endDate: newValue.format("YYYY-MM-DD"),
                                    });
                                }}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => <TextField fullWidth {...params} size="small" />}
                            />
                        </div>
                        <button
                            className='bg-indigo-800 text-white px-4 py-2 rounded-md w-full mt-4'
                            onClick={handleExport}
                        >
                            {isLoading ? 'Exporting...' : 'Export as Excel'}
                        </button>
                    </div>
                </LocalizationProvider>
            </Dialog>

            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>

    )
}